<i18n>
{
  "en": {
    "heading": "Featured Videos",
    "vip_badge": "VIP",
    "svip_badge": "SVIP",
    "annual_badge": "Annual",
    "limited_tag": "Limited Availability",
    "expired_tag": "Availability Ended",
    "future_tag": "Upcoming"
  },
  "ja": {
    "heading": "おすすめ動画",
    "vip_badge": "VIP",
    "svip_badge": "超VIP",
    "annual_badge": "年間",
    "limited_tag": "期間限定",
    "expired_tag": "配信期間終了",
    "future_tag": "近日配信"
  }
}
</i18n>

<template>
  <div class="contents">
    <div class="search-sub-menu">
      <div class="search-sub-menu__header">
        <h1 class="h1-dense" v-if="locale === 'en'">
          {{ $t('heading') }}: {{ curatedDetails.title }}
        </h1>
        <h1 class="h1-dense" v-else>
          {{ curatedDetails.title }}{{ $t('heading') }}
        </h1>
      </div>
      <div>{{ curatedDetails.body }}</div>
    </div>

    <div class="list list--default list--card">
      <div class="flex-grid">
        <!-- list item -->
        <div v-for="movie in curatedDetails.movies" :key="movie.movie_id" class="grid-item">
          <div class="entry" v-observe-visibility="{
            callback: (isVisible, entry) => visibilityChanged(isVisible, entry, movie.movie_date_id),
            once: true, // stop observing after it's become visible
            throttle: 200, // delay until running callback (visibility won't trigger if fast scrolling)
          }">
            <!-- movie grid item; populated if the visibility observer populated movie details for the movie -->
            <span
              v-if="movieDetails[movie.movie_date_id]"
              class="entry-media"
              :class="{ 'is-play': hasSampleMovie(movieDetails[movie.movie_date_id]) }"
              @click="showModalSample(movieDetails[movie.movie_date_id]); clickTrack('Curated Page', 'Play Curated Movie', movieDetails[movie.movie_date_id].Title)"
            >
              <div v-if="!newXPEligible && movieDetails[movie.movie_date_id].isVIP" class="tag-member tag-member--vip">{{ $t('vip_badge') }}</div>
              <div v-if="!newXPEligible && movieDetails[movie.movie_date_id].isSVIP" class="tag-member tag-member--svip">{{ $t('svip_badge') }}</div>
              <div v-if="!newXPEligible && movieDetails[movie.movie_date_id].isAnnual" class="tag-member tag-member--annual">{{ $t('annual_badge') }}</div>
              <div class="media-thum">
                <img :src="`${movieDetails[movie.movie_date_id].thumbnailSrc}`" @error="useFallbackImage(movie.movie_date_id)" loading="lazy" class="media-thum-image" />
              </div>
            </span>
            <component v-if="movieDetails[movie.movie_date_id]" :is="isFutureMovie(movieDetails[movie.movie_date_id]) ? 'div' : 'router-link'" :to="'' || `/movies/${movie.movie_date_id}/`" class="entry-meta">
              <div v-if="movieDetails[movie.movie_date_id].IsExpired" class="tag-status tag-status--expired">{{ $t('expired_tag') }}</div>
              <div v-if="movieDetails[movie.movie_date_id].IsLimited" class="tag-status tag-status--limited">{{ $t('limited_tag') }}</div>
              <div v-if="movieDetails[movie.movie_date_id].IsComingSoon" class="tag-status tag-status--future">{{ $t('future_tag') }}</div>
              <div class="meta-data">{{ movieDetails[movie.movie_date_id].ReleaseDate }}</div>
              <div v-if="locale == 'ja'">
                <div class="meta-title">{{ movieDetails[movie.movie_date_id].Title }}</div>
                <div class="meta-data">{{ movieDetails[movie.movie_date_id].Actor }}</div>
              </div>
              <div v-if="locale == 'en'">
                <div v-if="movieDetails[movie.movie_date_id].TitleEn != null && movieDetails[movie.movie_date_id].TitleEn != ''" class="meta-title">{{ movieDetails[movie.movie_date_id].TitleEn }}</div>
                <div v-else class="meta-title" v-for="(actress, index) in movieDetails[movie.movie_date_id].ActressesEn" :key="actress.$index">
                  {{ actress }}<span v-if="index + 1 < movieDetails[movie.movie_date_id].ActressesEn.length">,&nbsp;</span>
                </div>
              </div>
              <div class="meta-rating">
                <star-rating v-model="movieDetails[movie.movie_date_id].AvgRating" active-color="#FEB507" :increment="0.1" :star-size="14" :inline="true" :show-rating="false" :read-only="true"></star-rating>
              </div>
            </component>
            <!-- placeholder movie grid item; used if there are no details fetched yet (not visible) -->
            <span v-else class="entry-media">
              <div class="media-placeholder"></div>
            </span>
          </div>
        </div>
        <!-- /list item -->
      </div>
    </div><!-- /.list -->
  </div>
</template>

<script>
/* eslint max-len: 0 */
/* eslint no-shadow: 0 */
import md5 from 'md5';
import StarRating from 'vue-star-rating';
import MovieHelper from '@/assets/js/utils/movie';
import BifrostAPI from '@/assets/js/services/Bifrost/API';

const USE_PORTRAIT_POSTER = false;
const USE_LARGE_POSTER_FALLBACK = true;

export default {
  components: {
    'star-rating': StarRating,
  },
  data() {
    return {
      curatedDetails: {},
      movieDetails: {}, // keyed by movie id
      totalMovies: 0,
      posterFallbackTries: {},
    };
  },
  async created() {
    // md5 salt: changes to this will also need to be updated on the DL Admin Server's curated component worker
    const SALT = 'dM!^F#*3Hb9#G&yhpfr*';

    // get curated info
    const bfAPI = new BifrostAPI();
    const curatedMovies = await bfAPI.getCuratedMovieLists();
    const hashedListName = md5(this.$route.query.list + SALT);
    this.curatedDetails = curatedMovies[hashedListName];
    this.totalMovies = curatedMovies[hashedListName].movies.length;
  },
  computed: {
    locale() {
      return this.$i18n.locale;
    },
    newXPEligible() {
      return this.$store.getters['user/isNewXPEligible'];
    },
    userTypeID() {
      return this.$store.getters['user/typeID'];
    },
  },
  methods: {
    getMovieDetail(movieDateID) {
      const bfAPI = new BifrostAPI();
      if (!Object.prototype.hasOwnProperty.call(this.movieDetails, movieDateID)) {
        bfAPI.getMovieDetail(movieDateID).then((detail) => {
          const movieDetailExpanded = MovieHelper.expandDetails(
            detail,
            this.locale,
            this.userTypeID,
          );
          this.$set(this.movieDetails, movieDateID, movieDetailExpanded);
        });
      }
    },

    useFallbackImage(movieDateID) {
      // missing movie poster thumbnail, fallback to large poster or placeholder
      if (USE_LARGE_POSTER_FALLBACK && !this.posterFallbackTries[movieDateID]) {
        // attempt 1: poster thumbnail is missing, use large poster
        this.$set(
          this.movieDetails[movieDateID],
          'thumbnailSrc',
          `/assets/sample/${movieDateID}/str.jpg`,
        );
        this.posterFallbackTries[movieDateID] = true;
      } else {
        // attempt 2: large poster is missing, use no-image placeholder
        this.$set(
          this.movieDetails[movieDateID],
          'thumbnailSrc',
          (USE_PORTRAIT_POSTER) ? '/img/common/now_printing_portrait.png' : '/img/common/now_printing.png',
        );
      }
    },

    isFutureMovie(movie) {
      return (!movie.Status);
    },

    hasSampleMovie(movie) {
      return (Object.prototype.hasOwnProperty.call(movie, 'SampleFiles'));
    },

    showModalSample(movie) {
      if (this.hasSampleMovie(movie)) this.$modal.show('ui-modal-video', { movieId: movie.MovieID });
    },

    visibilityChanged(isVisible, entry, movieDateID) {
      if (isVisible) this.getMovieDetail(movieDateID);
    },

    clickTrack(category, action, label = null, value = null) {
      this.$analytics.trackEvent(category, action, label, value);
    },
  },
};
</script>

<style lang="scss" scoped>
.curated-description {
  white-space: pre-line;
}
.media-placeholder {
  height: 420px;
}
</style>
